<template>
  <div class="">
    <Header
      back="/integrations/list"
      class="pb-3"
      heading="Integration Detail"
    />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div v-if="isIntegrationLoading">
              <DetailSkeleton />
            </div>
            <div class="Integration-Main" v-else>
              <div class="row w-100 mt-2" v-if="!connectView">
                <div class="col-12 col-md-4 Integration-left">
                  <div class="integrationImage">
                    <LazyImage
                      :src="`${Integration.image}`"
                      :key="Integration.image"
                      style="width: 100%; height: auto; border-radius: 14px"
                      :alt="Integration.title"
                      :title="Integration.title"
                      class="rounded"
                    />
                  </div>

                  <div class="IntegrationBtns mt-5">
                    <div class="Connect">
                      <button
                        v-if="
                          Integration.installed == 'no' &&
                          !nonAuthIntegrationConditon
                        "
                        class="connect-btn actionBtn btn d-flex justify-content-center align-items-center"
                        @click="initiateConnectView"
                      >
                        <span v-if="!isAllowedLoading"
                          ><BIconPlusCircle class="mr-2" />Connect</span
                        >
                        <div
                          v-else
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                      <button
                        v-if="Integration.installed == 'yes'"
                        class="delete-btn actionBtn btn d-flex justify-content-center align-items-center"
                        @click="deleteIntegration(Integration.connection_id)"
                      >
                        <span><BIconTrashFill class="mr-2" />Delete</span>
                      </button>
                    </div>
                    <div class="helpSection">
                      <button
                        v-if="
                          Integration.installed == 'yes' &&
                          Integration.app_fields
                        "
                        class="help-btn btn mt-3"
                        @click="OpenUpdateIntegrationModal"
                      >
                        <span
                          ><BIconPencilSquare class="mr-2" />Update
                          {{ Integration.title }}</span
                        >
                      </button>
                      <button
                        class="help-btn btn mt-3"
                        v-if="
                          Integration.help_link && Integration.help_link !== ''
                        "
                        @click="openExternalLink(Integration.help_link)"
                      >
                        <span
                          ><BIconJournalBookmarkFill class="mr-2" />Get
                          Help</span
                        >
                      </button>
                      <button
                        class="help-btn btn mt-3"
                        v-if="
                          Integration.video_link &&
                          Integration.video_link !== ''
                        "
                        @click="openExternalLink(Integration.video_link)"
                      >
                        <span
                          ><BIconCameraVideoFill class="mr-2" />See Video</span
                        >
                      </button>
                    </div>
                  </div>
                  <div
                    class="integrationAppQuizess mt-5 w-100"
                    v-if="
                      Integration.installed == 'yes' &&
                      AppQuizess.length &&
                      Integration.id !== 11
                    "
                  >
                    <div class="PermissionHeader">
                      <p>Manage your Quizzes with {{ Integration.title }}</p>
                    </div>
                    <div class="mt-2 appQuizzesMain">
                      <div
                        class="singleAppQuiz mb-2"
                        v-for="(quiz, index) in AppQuizess"
                        :key="index"
                      >
                        <div class="d-flex align-items-center">
                          <div
                            class="actionDiv d-flex align-items-center pl-2"
                            style="flex: 0.1"
                          >
                            <div class="show-logo custom-control custom-switch">
                              <div>
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="`${quiz.quiz_id}-${quiz.quiz_title}`"
                                  v-model="quiz.status"
                                  style="cursor: pointer"
                                  @change="toggleStatus($event, quiz)"
                                />
                                <label
                                  class="custom-control-label"
                                  :for="`${quiz.quiz_id}-${quiz.quiz_title}`"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex quizTitle" style="flex: 0.9">
                            <!-- <p class="m-0" style="flex:0.05"><BIconArrowRight/></p> -->
                            <p class="m-0" style="flex: 0.95">
                              {{ quiz.quiz_title }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                   <!-- Shopify / Wix update -->
                   <div
                    class="integrationAppQuizess mt-5 w-75"
                    v-if="
                      (Integration.title == 'Shopify' ||
                        Integration.title == 'Wix') &&
                      shopifyWixQuiz.length
                    "
                  >
                    <div class="PermissionHeader">
                      <p>Send Data to {{ Integration.title }} customers</p>
                    </div>
                    <div class="mt-2 appQuizzesMain">
                      <div
                        class="singleAppQuiz mb-2"
                        v-for="(quiz, index) in shopifyWixQuiz"
                        :key="index"
                      >
                        <div class="d-flex align-items-center">
                          <div
                            class="actionDiv d-flex align-items-center pl-2"
                            style="flex: 0.1"
                          >
                            <div class="show-logo custom-control custom-switch">
                              <div>
                                <input
                                  v-if="Integration.id == 13"
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="`${quiz.id}-${quiz.title}`"
                                  v-model="quiz.add_shopify_lead"
                                  style="cursor: pointer"
                                  @change="
                                    toggleSaveDataFunc($event, quiz, index)
                                  "
                                />
                                <input
                                  v-else
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="`${quiz.id}-${quiz.title}`"
                                  v-model="quiz.add_wix_lead"
                                  style="cursor: pointer"
                                  @change="
                                    toggleSaveDataFunc($event, quiz, index)
                                  "
                                />
                                <label
                                  class="custom-control-label"
                                  :for="`${quiz.id}-${quiz.title}`"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div
                            class="d-flex quizTitle align-items-center"
                            style="flex: 0.9"
                          >
                            <!-- <p class="m-0" style="flex:0.05"><BIconArrowRight/></p> -->
                            <p class="m-0 d-flex align-items-center">
                              {{ quiz.title }}
                            </p>
                            <div
                              v-if="quiz.loader"
                              class="spinner-border spinner-border-sm ml-3"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Shopify Webhooks -->
                    <div class="mt-3" v-if="!isLoadingShopifyWebhook && shopifyWebhooks.length">
                      <div class="PermissionHeader">
                        <p>{{ Integration.title }} Webhook</p>
                      </div>
                      <div class="mt-2 appQuizzesMain">
                        <div
                          class="singleAppQuiz mb-2"
                          v-for="(webhook, index) in shopifyWebhooks"
                          :key="index"
                        >
                          <div class="d-flex align-items-center">
                            <div
                              class="actionDiv d-flex align-items-center pl-2"
                              style="flex: 0.1"
                            >
                              <div
                                class="show-logo custom-control custom-switch"
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    :id="`${index}-${webhook.topic}`"
                                    v-model="selectedShopifyWebhooks"
                                    style="cursor: pointer"
                                    :value="webhook"
                                    @change="
                                      toggleShopifyWebhook(webhook, index)
                                    "
                                  />

                                  <label
                                    class="custom-control-label"
                                    :for="`${index}-${webhook.topic}`"
                                  ></label>
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex quizTitle align-items-center"
                              style="flex: 0.9"
                            >
                              <!-- <p class="m-0" style="flex:0.05"><BIconArrowRight/></p> -->
                              <p class="m-0 d-flex align-items-center">
                                {{ webhook.topic }}
                              </p>
                              <div
                                v-if="loadingWebhook == webhook.topic"
                                class="spinner-border spinner-border-sm ml-3"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Shopify Webhooks -->
                  </div>
                </div>
                <div class="col-12 col-md-8 Integration-Right">
                  <div class="IntegrationTitle">
                    <p>{{ Integration.title }}</p>
                  </div>
                  <div class="IntegrationSubTitle mt-4">
                    <p class="short_descPadding">
                      {{ Integration.short_description }}
                    </p>
                  </div>

                  <div class="IntegrationDescription mt-4">
                    <!-- <p v-html="">{{Integration.description}}</p> -->
                    <p v-html="Integration.description"></p>
                  </div>

                  <div
                    class="IntegrationPermission mt-5"
                    v-if="Integration.permissions"
                  >
                    <div class="PermissionHeader">
                      <p><BIconLockFill class="mr-2" />Permissions</p>
                    </div>
                    <div class="permissionList mt-2">
                      <div
                        class="mb-1"
                        v-for="(
                          permission, index
                        ) in Integration.permissions.split(',')"
                        :key="index"
                      >
                        <p><BIconArrowRight class="mr-2" />{{ permission }}</p>
                      </div>
                    </div>
                  </div>

                 
                </div>
              </div>

              <div class="row w-100" v-else>
                <ConnectIntegration :integration="Integration" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <UpdateIntegrationModal
        ref="updateIntegrationRef"
        :integration="Integration"
        @updateIntegration="updateIntegration"
      />
      <DeleteModal @click="deleteIntegrationHandle" :isLoading="isDeleteLoading" id="removeIntegration" ref="removeIntegration" title="Delete Integration" item="integration" ></DeleteModal>
      
      <UpgradePlanModal
        ref="UpgradeUserPlanModalDetailIntegration"
        :title="upgradeModalTitle"
        :subText="upgradeModalsubText"
        @openPlansModalChild="openShopifyPlansModal"
      />
      <ShopifyPlansModal ref="PlansModalRefDetailIntegration" />
    </div>
  </div>
</template>

<script>
import {
  BIconPlusCircle,
  BIconJournalBookmarkFill,
  BIconCameraVideoFill,
  BIconLockFill,
  BIconArrowRight,
  BIconPencilSquare,
  BIconTrashFill,
} from "bootstrap-vue";
import DetailSkeleton from "./DetailSkeleton.vue";
import ConnectIntegration from "./ConnectIntegration.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import UpdateIntegrationModal from "./UpdateIntegrationModal.vue";

import ShopifyPlansModal from "../Dashboard/modal/PlansModal.vue";
import UpgradePlanModal from "../Dashboard/modal/UpgradePlanModal.vue";
import Header from "../Dashboard/Layout/Header.vue";
import DeleteModal from "../Dashboard/modal/DeleteModal.vue";
export default {
  components: {
    DeleteModal,
    BIconPlusCircle,
    BIconJournalBookmarkFill,
    BIconCameraVideoFill,
    DetailSkeleton,
    BIconLockFill,
    BIconArrowRight,
    ConnectIntegration,
    BIconPencilSquare,
    UpdateIntegrationModal,
    BIconTrashFill,

    UpgradePlanModal,
    ShopifyPlansModal,
    Header,
  },
  data() {
    return {
      integrationID:null,
      isDeleteLoading:false,
      isLoadingShopifyWebhook: false,
      loadingWebhook: null,
      shopifyWebhooks: [],
      selectedShopifyWebhooks: [],
      toggleSaveData: true,
      isIntegrationLoading: false,
      isAllowedLoading: false,
      Integration: {},
      connectView: false,
      AppQuizess: [],
      nonAuthIntegrations: ["shopify", "wix", "bc"],
      upgradeModalTitle: "Upgrade your plan to access the Integration.",
      upgradeModalsubText: `Your current Plan doesn't support the Integration. Please upgrade your account.`,
      PlatformIntegrations: ["Wix", "Shopify", "BigCommerce"],
      shopifyWixQuiz: [],
      // deleteSpinner:false,
    };
  },
 
  methods: {
    async deleteIntegrationHandle(){
       if(this.integrationID){
        try {
        this.isDeleteLoading = true
        let data = {
          connection_id: this.integrationID,
        };
        const response = await axios.post(`/integrationConnectionDisconnect`, data);
        if(response.status == 200 ){
          this.$refs.removeIntegration.close();
             this.$toasted.show("Integration removed", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
          this.$router.push({name:'Integrations'})
        } else{
          
              this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
       
      } catch (error) {
        console.log(error)
        if (error) {
          this.$toasted.show("Error occurred", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      } finally{
         this.isDeleteLoading = false
      }
       } else{
       
          this.$toasted.show("Something went wrong", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        
       }
      
    },
    async toggleShopifyWebhook(webhook, index) {
      this.loadingWebhook = webhook.topic;
      await axios
        .put("/shopify/webhooks/toggle", webhook)
        .then((response) => {
          this.$toasted.show(response.data.message, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          if (webhook.id == null) {
            this.shopifyWebhooks[index] = response.data.data;
          } else {
            this.shopifyWebhooks[index] = { id: null, topic: webhook.topic };
          }
          this.selectedShopifyWebhooks = this.shopifyWebhooks.filter(
            (webhook) => {
              if (webhook.id != null) return webhook;
            }
          );
        })
        .catch((error) => {
          this.$toasted.show("error while updating webhook", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          console.log(error);
        });
      this.loadingWebhook = null;
    },
    async getShopifyWebhooks() {
      this.isLoadingShopifyWebhook = true;
      // if (this.Integration.title === "Shopify") {
        await axios
          .get("/shopify/webhooks")
          .then((response) => {
            this.shopifyWebhooks = response.data.data.webhooks;
            this.selectedShopifyWebhooks = this.shopifyWebhooks.filter(
              (webhook) => {
                if (webhook.id != null) return webhook;
              }
            );
          })
          .catch((error) => {
            console.log(error.response);
          });
      // }
      this.isLoadingShopifyWebhook = false;
    },
    async toggleStatus(e, quiz) {
      if (quiz) {
        let QuizID = [];
        QuizID.push(quiz.quiz_id);
        try {
          let data = {
            connection_id: quiz.connection_id,
            quiz_id: QuizID,
          };
          if (e.target.checked) {
            data.status = true;
          } else {
            data.status = false;
          }
          const response = await axios.post(
            `/integrationConnectionQuizSave`,
            data
          );
          if (response.status == 200) {
            this.$toasted.show("Success", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });

            this.getIntegrationQuestion(this.Integration.id);
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
          throw error;
        }
      } else {
        this.$toasted.show("Something went wrong", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    async toggleSaveDataFunc(e, quiz, index) {
      try {
        this.shopifyWixQuiz[index].loader = true;
        let data = {};
        data.quizId = quiz.id;

        if (this.Integration.title == "Shopify") {
          if (e.target.checked) {
            data.add_shopify_lead = 1;
          } else {
            data.add_shopify_lead = 0;
          }
        } else {
          if (e.target.checked) {
            data.add_wix_lead = 1;
          } else {
            data.add_wix_lead = 0;
          }
        }

        const response = await axios.post(`/toggleShopifyWixIntegration`, data);
        if (response.status == 200) {
          this.$toasted.show("Success", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });

          this.getQuestionsListShopifyWix();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.shopifyWixQuiz[index].loader = false;
      }
    },
    openShopifyPlansModal() {
      this.$refs.PlansModalRefDetailIntegration.OpenModal();
    },
    OpenUpdateIntegrationModal() {
      this.$refs.updateIntegrationRef.OpenModal();
    },
    async initiateConnectView() {
      //  if(this.nonAuthIntegrations.includes(this.getUserType)){
      if (this.Integration.play_store_link) {
        window.open(this.Integration.play_store_link, "_blank");
      } else {
        let isAllowed = await this.isIntegrationAllowed(this.$route.params.id);
        if (isAllowed) {
          this.connectView = true;
        } else {
          this.$refs.UpgradeUserPlanModalDetailIntegration.OpenModal();
        }
      }
    },
    async isIntegrationAllowed(id) {
      this.isAllowedLoading = true;
      try {
        let data = {
          app_id: id,
        };
        const response = await axios.post(`/getIntegrationAllowed`, data);
        if (
          response.data.ok &&
          response.data.message == "Integration is allowed."
        ) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      } finally {
        this.isAllowedLoading = false;
      }
    },
    async getIntegration(id) {
      try {
        this.isIntegrationLoading = true;
        let data = {
          id: id,
        };
        const response = await axios.get(`/getIntegrationAppPrivate`, {
          params: data,
        });
        if (response.status == 200 && response.data.data.length == 1) {
          this.Integration = response.data.data[0];
          if (response.data.data[0].installed == "yes") {
            this.getIntegrationQuestion(this.Integration.id);
          }

          if (
            (this.Integration.title == "Shopify" ||
              this.Integration.title == "Wix") &&
            (this.getUserType == "shopify" || this.getUserType == "wix")
          ) {
            this.getIntegrationQuestion(this.Integration.id);
          }
        } else {
          this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      } finally {
        this.isIntegrationLoading = false;
      }
    },
    async deleteIntegration(id) {
      if (id) {
        this.integrationID=id
        this.$refs.removeIntegration.open(id);
      }
    },
    openExternalLink(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
    updateIntegration() {
      this.getIntegration(this.$route.params.id);
    },
    async getIntegrationQuestion(id) {
      try {
        let data = {
          app_id: id,
        };
        const response = await axios.post(`/getIntegrationAppQuizzes`, data);
        if (response.status == 200) {
          this.AppQuizess = response.data;
        } else {
          this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      }
    },
    async getQuestionsListShopifyWix() {
      try {
        const response = await axios.get(`/quizShortDetail`);
        if (response.status == 200) {
          let data = response.data.data.map((x) => {
            return { ...x, loader: false };
          });
          this.shopifyWixQuiz = data;
        } else {
          this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      }
    },
  },
  async mounted() {
    if (this.$route.params) {
      this.getIntegration(this.$route.params.id);
      if (this.$route.params.id == 13 || this.$route.params.id == 14) {
        this.getQuestionsListShopifyWix();
        // this.getShopifyWebhooks();
      }
      if (this.$route.params.id == 13 ) {
        this.getShopifyWebhooks();
      }
    }
  },
  computed: {
    ...mapGetters(["getNavbarData"]),
    getUserType() {
      return this.getNavbarData.userType;
    },

    nonAuthIntegrationConditon() {
      if (
        this.getUserType == "shopify" &&
        this.PlatformIntegrations.includes(this.Integration.title)
      )
        return true;
      if (
        this.getUserType == "wix" &&
        this.PlatformIntegrations.includes(this.Integration.title)
      )
        return true;
      if (
        this.getUserType == "bc" &&
        this.PlatformIntegrations.includes(this.Integration.title)
      )
        return true;
      return false;
    },
  },
};
</script>

<style scoped>
.Detail-Integration-Main-Container {
  padding: 2%;
  min-height: calc(100vh - 60px);
}

.goBackLink {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #7c8290;
}

.Integration-Main {
  padding: 1%;
}
.Integration-left .integrationImage {
  max-width: 255px;
  max-height: 255px;
  /* height: 255px; */
  border-radius: 24px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .integrationImage {
    width: 255px;
  }
}

.Integration-left .IntegrationBtns {
  width: 100%;
  /* max-width:225px; */
}

.connect-btn {
  width: 100%;
  height: 50px;
  background: #4d1b7e;
  border-radius: 100px;
  color: #ffffff;
}

.connect-btn span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  text-transform: capitalize;

  color: #ffffff;
}
.connect-btn:hover {
  color: #ffffff;
}
.delete-btn {
  width: 100%;
  height: 50px;
  background: rgba(225, 28, 28, 0.15);
  border-radius: 100px;
  color: #ffffff;
}

.delete-btn span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  text-transform: capitalize;

  color: #e11c1c;
}
.delete-btn:hover {
  color: #e11c1c;
}
.help-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50px;

  background: rgba(77, 27, 126, 0.1);
  border-radius: 100px;
}
.help-btn span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-transform: capitalize;
  color: #4d1b7e;
}
.help-btn:hover {
  color: #4d1b7e;
}

.Integration-Right .IntegrationTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 100%;
  color: #1f1f28;
}
.Integration-Right .IntegrationSubTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #7c8290;
}

.IntegrationDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #1f1f28;
}
.PermissionHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;

  color: #7c8290;
}

.IntegrationPermission {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #1f1f28;
}

.appQuizzesMain .quizTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1f1f28;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
</style>
